import * as React from "react"
import {animated, useSpring} from 'react-spring'
import {AnimatedButtonProps} from 'src/components/AnimatedButton'
import ProductButton from 'src/components/ProductButton'
import LinksLayout from 'src/components/LinksLayout'
import {fadeInFromLeftConfig, fadeInFromRightConfig} from 'src/utils/animations'

const AudioEquipment = (props) => {
  const fadeInFromLeft = useSpring(fadeInFromLeftConfig)
  const fadeInFromRight = useSpring(fadeInFromRightConfig)

  const getFluidImage = (imageName: string) => (
    props.data[imageName].childImageSharp.fluid
  )


  const productList: AnimatedButtonProps[] = [
    {
      backgroundImageData: getFluidImage('Mic'),
      link: 'https://amzn.to/3LTHUM3',
      fadeSpringClass: fadeInFromLeft,
      header: 'MXL V67G',
    },
    {
      backgroundImageData: getFluidImage('Maschine'),
      link: 'https://amzn.to/37oH2zN',
      fadeSpringClass: fadeInFromRight,
      header: 'Maschine Mikro MK1',
    },
    {
      backgroundImageData: getFluidImage('Keylab'),
      link: 'https://amzn.to/3vRXn9y',
      fadeSpringClass: fadeInFromLeft,
      header: 'Arturia Keylab 88 Essential',
    },
    {
      backgroundImageData: getFluidImage('Grand'),
      link: 'https://amzn.to/3LQrOCG',
      fadeSpringClass: fadeInFromRight,
      header: 'Studiologic SL88 Grand',
    },

    {
      backgroundImageData: getFluidImage('Impact'),
      link: 'https://amzn.to/39DWVDf',
      fadeSpringClass: fadeInFromLeft,
      header: 'Nektar Impact LX25+',
    },
    {
      backgroundImageData: getFluidImage('Interface'),
      link: 'https://amzn.to/3LU6wE9',
      fadeSpringClass: fadeInFromRight,
      header: 'Focusrite Scarlett 4i4',
    },
    {
      backgroundImageData: getFluidImage('Speaker'),
      link: 'https://amzn.to/3LVvdQK',
      fadeSpringClass: fadeInFromLeft,
      header: 'Yamaha HS8 White',
    },
    {
      backgroundImageData: getFluidImage('Stand'),
      link: 'https://amzn.to/3KNA84Z',
      fadeSpringClass: fadeInFromRight,
      header: 'IsoAcoustics Isolation Stands',
    },
    {
      backgroundImageData: getFluidImage('Saxophone'),
      link: 'https://amzn.to/3LWCzDo',
      fadeSpringClass: fadeInFromLeft,
      header: 'Yamaha YAS-280 Alto Saxophone',
    },
    {
      backgroundImageData: getFluidImage('Guitar'),
      link: 'https://amzn.to/3ymv6JS',
      fadeSpringClass: fadeInFromRight,
      header: 'Ibanez Artcore AS93',
    },
  ]

  return (
    <LinksLayout>
      {productList.map((linkData) => <ProductButton {...linkData}/>)}
    </LinksLayout>
  )
}

export default AudioEquipment

export const query = graphql`
  query {
    Keyboard: file(relativePath: { eq: "setup/keyboardmouse/keyboard.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    Mouse: file(relativePath: { eq: "setup/keyboardmouse/mouse.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },

    Mic: file(relativePath: { eq: "setup/audio/mic.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    Maschine: file(relativePath: { eq: "setup/audio/maschine.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    Keylab: file(relativePath: { eq: "setup/audio/keylab.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    Grand: file(relativePath: { eq: "setup/audio/grand.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    Impact: file(relativePath: { eq: "setup/audio/impact.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    Interface: file(relativePath: { eq: "setup/audio/interface.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    Speaker: file(relativePath: { eq: "setup/audio/speaker.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    Impact: file(relativePath: { eq: "setup/audio/impact.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    Stand: file(relativePath: { eq: "setup/audio/stand.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    Saxophone: file(relativePath: { eq: "setup/audio/saxophone.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    Guitar: file(relativePath: { eq: "setup/audio/guitar.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
  }
`