import React, {ReactElement} from 'react'
import {animated} from 'react-spring'
import {Box, Grid, makeStyles, Typography} from '@material-ui/core'
import BackgroundImage from 'gatsby-background-image'
import {Link} from 'gatsby'
import classnames from 'classnames'
import { FluidObject } from 'gatsby-image'

const useStyles = makeStyles({
  cardContainer: {
    margin: '8px 16px',
  },
  card: {
    boxShadow: "none",
    flexGrow: 1,
    backgroundColor: '#dadada',
    borderTopLeftRadius: '8px',
    borderBottomLeftRadius: '8px',
    overflow: 'hidden'
  },
  buttonCard: {
    boxShadow: "none",
    flexGrow: 1,
    backgroundColor: '#dadada',
    borderTopRightRadius: '8px',
    borderBottomRightRadius: '8px',
    overflow: 'hidden'
  },
  headerText: {
    fontWeight: 600,
    // fontSize: 18,
    color: '#fff',
    padding: '4px 0'
  },
  subheaderText: {
    fontSize: 14,
    padding: '4px 0',
    color: '#eaeaea'
  },
  musicIcon: {
    height: '24px',
    padding: '0 6px',
    objectFit: 'cover',
  },
  backgroundImage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '100%',
    boxShadow: 'inset 0 0 0 2000px rgb(0 0 0 / 100%)'
  },
  buttonBackground: {
    "&, &:before, &:after": {boxShadow: 'inset 0 0 0 2000px rgb(10 10 10 / 90%) !important'}
  }
})

// TODO: extract type
export type ProductButtonProps = {
  backgroundImageData: FluidObject;
  page?: string;
  link?: string;
  fadeSpringClass?: React.CSSProperties;
  image?: string;
  header?: string;
  subheader?: string;
  musicIcons?: string[];
  backgroundImageClassName?: string;
  alt?: string;
}

const LinkWrapper = (props: ProductButtonProps & {children: ReactElement}): ReactElement => {
  if(props.page) {
    return (
      <Link to={props.page}>
        {props.children}
      </Link>
    )
  }

  if (props.link) {
    return (
      <a href={props.link} target='_blank'>
        {props.children}
      </a>
    )
  }

  return <>{props.children}</>
}

const ProductButton = (props: ProductButtonProps): ReactElement => {
  const classes = useStyles();

  return (
    <animated.div style={props.fadeSpringClass}>
      <Box className={classes.cardContainer}>
        <LinkWrapper {...props}>
          <Grid container>
            <Grid item xs={4}>
                <Box className={classes.card} height={100}>
                    <BackgroundImage
                      className={classnames(classes.backgroundImage)} 
                      fluid={props.backgroundImageData}>
                      {props.musicIcons && <Box mb={0.5} display="flex" flexDirection="row">
                          {props.musicIcons.map((icon, i) => {
                            return <img key={i} className={classes.musicIcon} src={icon}/>
                          })}
                      </Box>}
                    </BackgroundImage>
                </Box>
            </Grid>
            <Grid item xs={8}>
              <Box className={classes.buttonCard} height={100}>
                <BackgroundImage
                  className={classnames(classes.buttonBackground, classes.backgroundImage)} 
                  fluid={props.backgroundImageData}>
                  {props.musicIcons && <Box mb={0.5} display="flex" flexDirection="row">
                      {props.musicIcons.map((icon, i) => {
                        return <img key={i} className={classes.musicIcon} src={icon}/>
                      })}
                  </Box>}
                  <Box px={2}>

                    {props.header && <Typography variant='body1' align='center' className={classes.headerText}>{props.header}</Typography>}
                    {props.subheader && <div className={classes.subheaderText}>{props.subheader}</div>}
                  </Box>
                </BackgroundImage>
              </Box>
            </Grid>
          </Grid>
        </LinkWrapper>
      </Box>
    </animated.div>

  )
}

export default ProductButton